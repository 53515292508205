import fullpage from 'fullpage.js';


const header = document.querySelector('.header')
const anchors = header.querySelectorAll('.link')

const removeActiveAll = () => {
  anchors.forEach((anchor) => {
    anchor.classList.remove('active')
  })
}

const addActive = (destination) => {
  anchors.forEach((anchor) => {
    let section = anchor.href.slice(anchor.href.indexOf('#') + 1)

    if(destination == section){
      anchor.classList.add('active')
    } else{
      anchor.classList.remove('active')
    }
  })
}

// Initializing it
var fullPageInstance = new fullpage('#fullpage', {
  // licenseKey: 'YOUR_KEY_HERE',
  // navigation: true,
  // scrollHorizontally: true,
  // dragAndMove: true,
  // touchSensitivity: 15,
  scrollBar: true,
  autoScrolling: true,
  scrollOverflow: false,

  afterLoad: function(origin, destination, direction, trigger){
    var origin = this;

    if(destination.anchor == 'hero'){
      removeActiveAll()
      header.classList.add('transparent')
    } else {
      header.classList.remove('transparent')
      addActive(destination.anchor)
    }
  }

});