export default class Marquees {
  constructor(node, index) {
    this.node = node
    this.index = index

    this.innerContainer = this.node.querySelector('.marquee-inner')

    this.init()
  }

  init() {
    // console.log('  -> Marquees init')
    const contentDuplicate = this.innerContainer.cloneNode(true)
    contentDuplicate.setAttribute('aria-hidden', true)
    contentDuplicate.classList.add('cloned')
    this.node.append(contentDuplicate)
    this.node.classList.add('marquee--enable-animation')
  }

  destroy() {
    // console.log('  -> Marquees destroy', this.node)
    this.node.classList.remove('marquee--enable-animation')
    const contentDuplicate = this.innerContainer.nextSibling
    contentDuplicate.remove
  }

  static destroyAll() {
    // console.log('  -> Marquees destroy all')
    Marquees.items.forEach((elem) => {
      elem.destroy()
    })
  }

  static init() {
    // console.log('== Marquees components ==')
    Marquees.items = Array.from(document.querySelectorAll('.marquee')).map(
      (node, index) => new Marquees(node, index)
    )
  }
}
