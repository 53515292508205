import Swiper from 'swiper'
import { Autoplay, Navigation } from 'swiper/modules'

export default class Sliders {
  constructor(slider, index) {
    this.slider = slider
    this.index = index
    this.slides = Array.from(this.slider.querySelectorAll('.swiper-slide'))

    this.options = this.setOptions()
    this.initSlider()
  }

  setOptions() {
    let options = {}

    if (this.slider.classList.contains('swiper--vertical')) {
      options = {
        modules: [Autoplay],
        centeredSlides: true,
        direction: 'vertical',
        loop: true,
        slidesPerView: 3,
        allowTouchMove: false,
        speed: 1000,
        autoplay: {
          delay: 1500,
          disableOnInteraction: false,
          reverseDirection: true,
        },
        breakpoints: {
          0: {
            centeredSlides: false,
            slidesPerView: 2,
          },
          768: {
            centeredSlides: true,
            slidesPerView: 3,
          }
        },
        // on: {
        //   slideChangeTransitionStart: function () {
        //     let prev = document.querySelector('.swiper-slide-prev')
        //     let current = document.querySelector('.swiper-slide-active')
        //     let next = document.querySelector('.swiper-slide-next')

        //     if(prev) prev.classList.add('gradient-text')
        //     current.classList.remove('gradient-text')
        //     setTimeout(() => {
        //       next.classList.add('gradient-text')
        //     }, 2000);
        //   },
        // }
      }
    }

    if (this.slider.classList.contains('swiper--case-study')) {
      options = {
        modules: [Navigation],
        grabCursor: true,
        slidesPerView: 1,
        spaceBetween: 0,
        // autoHeight: true,
        updateOnWindowResize: true,
        loop: false,
        speed: 600,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      }
    }

    return options
  }

  initSlider() {
    console.debug('  -> Sliders init')
    let swiperInstance = new Swiper(this.slider, this.options)

    swiperInstance.init()
    this.swiperInstance = swiperInstance
  }

  destroy() {
    console.debug('  -> Sliders destroy')
    this.swiperInstance.destroy()
  }

  static destroyAll() {
    console.debug('  -> Sliders destroy all')
    Sliders.items.forEach((slider) => {
      slider.destroy()
    })
  }

  static init() {
    console.debug('== Sliders components ==')
    Sliders.items = Array.from(document.querySelectorAll('.swiper')).map(
      (slider, index) => new Sliders(slider, index)
    )
  }


}