import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {DrawSVGPlugin} from 'gsap/DrawSVGPlugin'
import {TextPlugin} from 'gsap/TextPlugin'
// import {SplitText} from 'gsap/SplitText'

gsap.registerPlugin(ScrollTrigger)
// gsap.registerPlugin(SplitText)
gsap.registerPlugin(DrawSVGPlugin) 
gsap.registerPlugin(TextPlugin) 

export default class Animations {
  static init() {
    console.debug('== Animations components ==')

    // const main = document.querySelector('.main-wrapper')

    // // Homepage hero animation
    // if (main.classList.contains('front-page')) {
    //   console.debug('  -> Animate main viewport')

    //   const headerWrapper = document.querySelector('.header-wrapper')
    //   const sectionHero = document.querySelector('.section--hero')

    //   const tlMainViewport = gsap.timeline()
    //   tlMainViewport
    //     .from(sectionHero, {
    //       autoAlpha: 0,
    //       delay: 0.125,
    //       duration: 1,
    //       ease: 'expo.out',
    //     })
    //     .from(headerWrapper, {
    //       autoAlpha: 0,
    //       delay: -0.725,
    //       duration: 1,
    //       ease: 'expo.out',
    //     })
    // }

    // General animations
    console.debug('  -> Animate general components')

    const animateRight = document.querySelectorAll('.animate-right')
    if (animateRight.length != 0) {
      animateHorizontal(animateRight, 20)
    }

    const animateLeft = document.querySelectorAll('.animate-left')
    if (animateLeft.length != 0) {
      animateHorizontal(animateLeft, -20)
    }

    const animateBottom = document.querySelectorAll('.animate-bottom')
    if (animateBottom.length != 0) {
      animateVertical(animateBottom, 20)
    }

    const animateTop = document.querySelectorAll('.animate-top')
    if (animateTop.length != 0) {
      animateVertical(animateTop, -20)
    }

    const animateFadeItems = document.querySelectorAll('.animate-fade')
    if (animateFadeItems.length != 0) {
      animateFade(animateFadeItems)
    }

    const animateSvgItems = document.querySelectorAll('.animate-svg')
    if (animateSvgItems.length != 0) {
      animateSvg(animateSvgItems)
    }

    const words = ['Peace', 'Future', 'Integration', 'Care', 'Love', 'Resilience']

    const animateText = document.querySelector('#animated-text')
    if (animateText.length != 0) {
      animateLetters(animateText, words)
    }

    // const animateBottomStagger = document.querySelectorAll('.animate-bottom-stagger')
    // if (animateBottomStagger.length != 0) {
    //   animateBottomStagger(animateBottomStagger, 20, 0.15)
    // }
  }

  static destroyScrolls() {
    ScrollTrigger.killAll()
  }
}

function animateHorizontal(items, offset) {
  items.forEach((item) => {
    let tlItem = gsap.timeline({paused: true})

    tlItem.from(item, {
      autoAlpha: 0,
      duration: 2,
      delay: 0.5,
      x: offset,
      ease: 'expo.out',
    })

    ScrollTrigger.create({
      trigger: item,
      start: 'top 95%',
      end: 'bottom',
      animation: tlItem,
      markers: false,
      toggleActions: 'play none none reverse',
    })
  })
}

function animateVertical(items, offset, start = 'top 95%') {
  items.forEach((item) => {
    let tlItem = gsap.timeline({paused: true})

    tlItem.from(item, {
      autoAlpha: 0,
      duration: 2,
      delay: 0.5,
      y: offset,
      ease: 'expo.out',
    })

    ScrollTrigger.create({
      trigger: item,
      start: start,
      end: 'bottom',
      animation: tlItem,
      markers: false,
      toggleActions: 'play none none reverse',
    })
  })
}

function animateFade(items) {
  items.forEach((item) => {
    let tlItem = gsap.timeline({paused: true})

    tlItem.from(item, {
      autoAlpha: 0,
      duration: 2,
      delay: 0.5,
      ease: 'expo.out',
    })

    ScrollTrigger.create({
      trigger: item,
      start: 'top 95%',
      end: 'bottom',
      animation: tlItem,
      markers: false,
      toggleActions: 'play none none reverse',
    })
  })
}

function animateSvg(items) {
  items.forEach((item) => {
    // console.log('SVG animated item:', item)
    let paths = item.querySelectorAll('path')

    paths.forEach((path) => {
      // console.log('SVG animated path:', path)
      let tlPath = gsap.timeline({paused: true})

      gsap.set(path, {visibility:"visible"});

      tlPath.to(path, {
        duration: 4,
        drawSVG: 0,
        ease:"power1.inOut"
      })

      ScrollTrigger.create({
        trigger: item,
        start: 'top 95%',
        end: 'bottom',
        animation: tlPath,
        markers: false,
        toggleActions: 'play none none reverse',
      })
    })
  }) 
}

function animateLetters(item, words) {
  let text = item.querySelector('.text')
  let cursor = item.querySelector('.cursor')
  gsap.to(cursor, { opacity: 0, repeat: -1, yoyo: true, duration: 0.5, ease: "power2.inOut" })
  let tlMaster = gsap.timeline({repeat: -1})

  words.forEach((word) => {
    let tlItem = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 0.75})
    tlItem.to(text, { 
      duration: 0.75,
      text: word
    })
    tlMaster.add(tlItem)
  })
}

// function animateBottomStagger(items, offset, stagger) {
//   let tlItem = gsap.timeline({paused: true})

//   tlItem.from(items, {
//     autoAlpha: 0,
//     duration: 1,
//     y: offset,
//     ease: 'expo.out',
//     stagger: stagger,
//     delay: 0.25,
//   })

//   ScrollTrigger.create({
//     trigger: items,
//     start: 'top 95%',
//     end: 'bottom 90%',
//     animation: tlItem,
//     markers: false,
//     toggleActions: 'play none none reverse',
//   })
// }