import Animations from './modules/Animations'
import Marquees from './modules/Marquees'
import Sliders from './modules/Sliders'

export default class Main {
  constructor() {
    this.init()
  }

  init() {
    Sliders.init()

    window.addEventListener('load', () => {
      Animations.init()
      Marquees.init()
    })
  }
}

const main = new Main()
